import React, { useState, useEffect, useContext } from "react";

//mui
import {
  Grid, Paper, FormControl, InputLabel, MenuItem,
  Select, Typography, Button, CircularProgress,
  FormControlLabel, Checkbox
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import AutoComplete from '@material-ui/lab/Autocomplete'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ArrowBackIos as BackIcon, Clear, Add } from "@material-ui/icons";

import { API } from "aws-amplify";

import DateFnsUtils from '@date-io/date-fns';
import currency from "currency.js";

import { userContext } from "../../context/User";
import { customersContext } from "../../context/Customers";
import { checkRate, checkQuantity } from "../../libs/Errors";

// styles
import useStyles from "./styles";

export default function Create(props) {
  const user = useContext(userContext);
  const customers = useContext(customersContext);
  const classes = useStyles();

  const [customer, setCustomer] = useState({
    rates: { "standard": 0.00 }
  });
  const [items, setItems] = useState([]);
  const [customerNum, setCustomerNum] = useState("");
  const [customerNumErr, setCustomerNumErr] = useState(false);
  const [note, setNote] = useState('');

  const [newRate, setNewRate] = useState('');
  const [newPrice, setNewPrice] = useState(0);
  const [newQuantity, setNewQuantity] = useState(0);
  const [newInside, setNewInside] = useState(false);

  const [openTicketErr, setOpenTicketErr] = useState(false);
  const [openTicketId, setOpenTicketId] = useState(null);

  const [date, setDate] = useState(new Date(Date.now()));
  const [dateErr, setDateErr] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // init
  useEffect(() => {
    if (!user || !customers) return; // wait for userInfo

    if (user.role === "customer") {
      setCustomer(Object.values(customers)[0]);
      setCustomerNum(Object.values(customers)[0].customer_number);
    }
  }, [user, customers]);

  // when you change accounts clear the items list
  useEffect(() => {
    setItems([]);
    setNewRate("");
    setNewPrice(0);
    setNewQuantity(0);
    setNewInside(false);
  }, [customer, customerNum])

  // return a new array with item added
  function addItem() {
    // check to see if you need add to a previous entry
    let match = items.findIndex(item => item.rate === newRate && item.inside === newInside)
    if (match !== -1) {
      let temp = items;
      temp[match].quantity = parseInt(temp[match].quantity) + parseInt(newQuantity)
      return temp;
    }
    else {
      return [
        ...items,
        {
          rate: newRate,
          price: newPrice,
          quantity: newQuantity,
          inside: newInside
        }
      ]
    }
  };

  async function handleCreate() {
    // reset form errors
    setDateErr(false);
    setCustomerNumErr(false);
    setOpenTicketErr(false);

    let updatedItems = items;

    // if the new item form isn't empty, add it to the list of items
    if (!(checkRate(newRate) || checkQuantity(newQuantity))) {
      updatedItems = addItem();
    }

    updatedItems = updatedItems.map((item) => (
      {
        ...item,
        "price": currency(item.price).value,
        "quantity": parseInt(item.quantity),
      }
    ))

    // check store num (double check it exists in the db)
    const params = { queryStringParameters: { user_id: user.user_id } };
    const resp = await API.get("api", "/customers/" + customerNum.padStart(4, '0'), params);
    if (!customerNum || !resp) {
      setCustomerNumErr(true);
      return;
    }

    // get date
    if (!date || date.toString() === 'Invalid Date' || date > new Date(Date.now())) {
      setDateErr(true);
      return;
    }

    // validate store, get hauler, check if store has open ticket
    setIsLoading(true);
    let openTicket = false;
    await API.get("api", "open_tickets/" + customer.customer_id).then(resp => {
      if (resp) {
        setOpenTicketErr(true);
        setOpenTicketId(resp.ticket_id);
        setIsLoading(false);
        openTicket = true;
      }
    });

    if (openTicket) return;

    // create ticket
    API.post("api", "create_ticket", {
      body: {
        customer_id: customer.customer_id,
        customer_number: customerNum.padStart(4, '0'),
        customer_name: customer.name,
        note: note,
        items: updatedItems,
        role: user.role
      }
    }).then(() => props.history.push('/tickets'));
  }

  return user && customers && Object.values(customers).length > 0 && ( // TODO: could use loading animation here
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>

        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Create Pickup Request
          </Typography>

          <Grid container id={"parent"} rowSpacing={9}> { /*+ Parent Container */}
            <Grid item container id={"top-row"} style={{ justifyContent: "space-between" }}> { /*+ Top Row */}
              <Grid id={"date-picker"} item xs={6} sm={5}> { /*+ Date Picker*/}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                  <KeyboardDatePicker
                    error={dateErr}
                    disableToolbar
                    variant="inline"
                    format="MM-dd-yyyy"
                    label="Date"
                    inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
                    value={date}
                    onChange={e => setDate(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    autoOk={true}
                  />

                </MuiPickersUtilsProvider>
              </Grid>{/*- Date Picker */}

              <Grid container item xs={6}> {/*+ Customer Number */}
                {
                  user.role === "admin" ?

                    <Grid container item>
                      <Grid item xs={12}>
                        <TextField // admins get a text field where you can enter the Account number
                          required
                          fullWidth
                          disabled={user.role !== "admin"}
                          error={customerNumErr}
                          value={customerNum}
                          onChange={async (e) => {

                            setCustomerNum(e.target.value)
                            const params = { queryStringParameters: { user_id: user.user_id } }
                            //check db for existence of customer with this number
                            const resp = await API.get("api", "/customers/" + e.target.value.padStart(4, '0'), params);
                            if (!resp) {
                              setCustomerNumErr(true);
                              return
                            }
                            setCustomer(resp);
                            setCustomerNumErr(false);
                          }}
                          helperText={customerNumErr ? "Invalid Acct#" : "Acct#"}
                          inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
                        />
                        <AutoComplete
                          fullWidth
                          options={Object.values(customers)}
                          getOptionLabel={(option) => option.name ? option.name : ""}
                          value={customer || null}
                          renderInput={(params) => <TextField style={{ width: "100%" }}{...params} label="Account Name" />}
                          onChange={(event, customer) => {
                            if (customer) {
                              setCustomerNum(customer.customer_number);
                              setCustomerNumErr(false);
                              setCustomer(customer);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    :
                    <FormControl fullWidth>
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Select // regular accounts get a dropdown containing any accounts they own
                            label="Account"
                            value={customerNum}
                            defaultValue={""}
                            onChange={(e) => {
                              const acct = Object.values(customers).find((acct) => acct.customer_number === e.target.value)
                              if (!acct) {
                                setCustomerNumErr(true);
                                return;
                              }
                              setCustomer(acct);
                              setCustomerNum(acct.customer_number);
                            }}
                          >
                            {
                              Object.values(customers).map((val) => {
                                return <MenuItem value={val.customer_number}>{`${val.name} (${val.customer_number}) `}</MenuItem>
                              })
                            }
                          </Select>
                        }
                        label="Account"
                      />
                    </FormControl>
                }
              </Grid> {/*- Customer Number */}
            </Grid> {/*- Top Row */}

            <Grid item style={{ paddingTop: "30px" }} xs={12}> {/*+ Items label */}
              <Typography style={{ fontSize: "1.15rem" }}>Items</Typography>
            </Grid> {/*- Items label */}

            <Grid container id={"current-items"} > {/*+ Current Items */}
              {
                items.map((item, index) => {
                  return (
                    <Grid item container> {/*+ Item row */}
                      <Grid item xs={1}> {/*+ Delete Button */}
                        <Button
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            minHeight: "20px",
                            minWidth: "20px",
                            marginTop: "10px"
                          }}
                          variant="outlined"
                          onClick={() => {
                            setItems(items.filter((_, i) => i !== index));
                          }}
                          startIcon={<Clear style={{ marginLeft: "10px" }} />}
                          size="small"
                        />
                      </Grid> {/*- Delete Button */}

                      <Grid item xs={3}> {/*+ Rate Name */}
                        <TextField
                          value={item.rate}
                          disabled
                          error={item.rate === ""}
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              if (i === index) {
                                return { ...item, "rate": e.target.value };
                              }
                              return item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid> {/*- Rate Name */}

                      <Grid item xs={2}> {/*+ Price */}
                        <TextField
                          value={item.price}
                          disabled
                          error={checkRate(item.price) || item.price === ""}
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              if (i === index) {
                                return { ...item, "price": e.target.value };
                              }
                              return item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid > {/*- Price */}

                      <Grid item xs={2}> {/*+ Quantity */}
                        <TextField
                          value={item.quantity}
                          disabled
                          error={checkQuantity(item.quantity)}
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              return i === index ? (
                                { ...item, "quantity": e.target.value }
                              ) : item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid > {/*- Quantity */}

                      <Grid item xs={1}> {/*+ Inside/Outside */}
                        <FormControl style={{ marginBottom: "5px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.inside}
                                disabled
                                onChange={(e) => {
                                  setItems(items.map((item, i) => {
                                    return i === index ? (
                                      { ...item, "inside": e.target.checked }
                                    ) : item;
                                  }))
                                }}
                              />}
                            label="Inside" />
                        </FormControl>
                      </Grid> {/*+ Inside/Outside */}
                    </Grid>
                  )
                })
              }
            </Grid>

            <Grid container id={"new-item"} style={{ paddingTop: "15px" }} > {/*+ New Item */}

              <Grid item container spacing={2} >

                <Grid item xs={1}>
                  <Button
                    style={{
                      maxWidth: "20px",
                      maxHeight: "20px",
                      minHeight: "20px",
                      minWidth: "20px",
                      marginTop: "25px",
                    }}
                    disabled={newRate === "" || checkRate(newPrice) || checkQuantity(newQuantity)}
                    variant="outlined"
                    onClick={() => {
                      setItems(addItem());
                      setNewRate("");
                      setNewPrice(0);
                      setNewQuantity(0);
                    }}
                    startIcon={<Add style={{ marginLeft: "10px" }} />}
                    size="small"
                  />
                </Grid>

                <Grid item xs={4}>
                  {
                    !customer.rates.hasOwnProperty(newRate) && newRate !== "" ? (
                      <TextField
                        value={newRate === "Add Rate" ? "" : newRate}
                        helperText={"New Rate Name"}
                        error={newRate === ""}
                        onChange={(e) => {
                          if (customer.rates.hasOwnProperty(e.target.value)) {
                            setNewPrice(customer.rates[e.target.value])
                          }
                          setNewRate(e.target.value);
                        }
                        }
                        style={{ marginRight: "20px", marginTop: "15px" }} />) :
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Rate</InputLabel>
                        <Select
                          style={{ width: "100%" }}
                          error={newRate === ""}
                          value={newRate}
                          onChange={(e) => {
                            setNewRate(e.target.value)
                            setNewPrice(customer.rates[e.target.value] ? customer.rates[e.target.value] : "0.00")
                          }}
                        >
                          {
                            Object.entries({ ...customer.rates, "Add Rate": "0.00" }).map(([key, value]) => {
                              return (
                                <MenuItem value={key}>{key}</MenuItem>
                              );
                            })
                          }
                        </Select>
                      </FormControl>
                  }
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    required
                    label="Price"
                    disabled={customer.rates.hasOwnProperty(newRate)}
                    value={newPrice}
                    onChange={(e) => setNewPrice(e.target.value)}
                  />
                </Grid >

                <Grid item xs={2}>
                  <TextField
                    required
                    label="Qty."
                    error={checkQuantity(newQuantity)}
                    value={newQuantity}
                    onChange={(e) => setNewQuantity(e.target.value)}
                  />
                </Grid >

                <Grid item xs={1}>
                  <FormControl style={{ marginTop: "15px" }}>
                    <FormControlLabel control={<Checkbox onChange={(e) => setNewInside(Boolean(e.target.checked))} checked={(newInside)} />} label="Inside" />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid style={{ paddingTop: "30px" }} item xs={12}>
              <span
                style={{
                  fontSize: "1.15rem",
                  paddingRight: "3px",
                }}
              >
                Notes
              </span>
              <FormControl fullWidth>
                <TextField
                  multiline
                  name="notes"
                  variant="outlined"
                  minRows={5}
                  onChange={(e) => setNote(e.target.value)}
                  style={{
                    wordWrap: "break-word",
                    fontSize: "1.15rem",
                    float: "left",
                    overflowY: "auto",
                    width: "100%",
                  }}
                  value={note}
                />
              </FormControl>
            </Grid>
          </Grid>
          {
            openTicketErr && (
              <div style={{ fontSize: "1.15rem", marginTop: "8px", color: "#f44336" }}>
                There is already an Open/Pending ticket for this store.
                <div
                  style={{ textDecoration: "underline", fontWeight: "600", color: '#FF7F01', cursor: 'pointer', textAlign: 'center' }}
                  onClick={() => props.history.push('/ticket?ticket_id=' + openTicketId)}
                >
                  View Ticket
              </div>
              </div>
            )
          }
          <div style={{ display: "flex", "width": "100%" }}>
            <span
              style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
              onClick={() => {
                if (isLoading) return;
                props.history.goBack();
              }}
            >
              <BackIcon />
              <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                Back
            </span>
            </span>
            {isLoading ? (
              <CircularProgress size={26} style={{ marginLeft: "auto", marginTop: "20px" }} />
            ) : (
                <Button
                  style={{ marginLeft: "auto", marginTop: "10px" }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.backButton}
                  onClick={handleCreate}
                >
                  Create
                </Button>
              )}
          </div>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
