import React from "react";
import { Grid, Typography, Link } from "@material-ui/core"

export default function AccountList({ accounts, customers }) {
  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "600" }}>Accounts:</Typography>
      </Grid>
      {
        accounts.map((customer_id, _) => {
          return (
            <Grid container item xs={12}>
              <Grid item xs={10} style={{ paddingLeft: "30px" }}>
                <Link href={`/customer?customer_id=${customer_id}`}><Typography>{customers[customer_id].name}</Typography></Link>
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
}
