/***************************************************/
// Customer.js
//
//	A form to display information for a single customer.
//	Can be set to edit/create/view modes
/***************************************************/
import React, { useEffect, useState, useContext } from "react";
import { userContext } from "../../context/User";

import { CustomerInfo } from "../../components/Customer/CustomerInfo";
import CustomerInfoForm from "../../components/Customer/CustomerInfoForm"

//mui
import {
  Grid,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";

import { API } from "aws-amplify";


// styles
import useStyles from "./styles";

export default function Customer({
  mode = "view",
  customerData = {},
  onSubmit,
  ...props
}) {
  const classes = useStyles();
  const user = useContext(userContext);

  //state
  const [customer, setCustomer] = useState({
    name: "",
    city: "",
    address: "",
    tax_rate: null,
    rates: {},
    emails: [],
    notes: "",
    created_by: "",
    after_hours: false,
    saturday_pickup: false,
  });

  // if mode or data changes, populate customer data if necessary
  useEffect(() => {
    if (mode !== "create" && customerData) {
      setCustomer(customerData);
    }
  }, [mode, customerData]);

  return user && (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Account Info
          </Typography>

          {mode === "view" ? (
            <CustomerInfo admin={user.role === "admin"} customer={customer} />
          ) : (
              <CustomerInfoForm admin={user.role === "admin"} customer={customer} setCustomer={setCustomer} />
            )}

          <div style={{ display: "flex", width: "100%", paddingTop: "20px"}}>
            <span
              style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
              onClick={() => props.history.goBack()}
            >
              <BackIcon />
              <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                Back
              </span>
            </span>
            {
              user.role === "admin" ? (
                <>
                  <Button
                    style={{ backgroundColor: "red", marginLeft: "auto", marginTop: "10px" }}
                    variant="contained"
                    size="large"
                    className={classes.backButton}
                    onClick={(e) => {
                      e.stopPropagation(); // stop the onRowClick event

                      if (window.confirm(`Are you sure you would like to delete ${customer.name}`)) {

                        let params = { queryStringParameters: { user_id: user.user_id } };
                        API.del('api', 'customers_delete/' + customer.customer_id, params).then(_ => {
                          props.history.push("/customers");
                        })
                      }
                    }}
                  >
                    Delete
              </Button>
                  <Button
                    style={{ marginLeft: "auto", marginTop: "10px" }}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.backButton}
                    onClick={() => {
                      onSubmit(customer);
                    }}
                  >
                    {mode === "view" ? "Update" : "Submit"}
                  </Button>
                </>
              ) :
                <></>
            }
          </div>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}
