/****************************************************/
// Customers.js
//
// A table of all customers in the database.
// Rows:
// Name|City|Emails|After Hours|Rates|Last Pickup|
/****************************************************/
import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";

import useStyles from "./styles";
import { usersContext } from "../../context/Users";

function convertDateTime(dt) {
  return new Date(dt).toLocaleDateString({
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export default function Users(props) {
	const users= useContext(usersContext);
	const classes = useStyles();

	function getMuiTheme() {
		return createTheme({
			overrides: {
				MuiTableRow: {
					hover: {
						"&$root": {
							"&:hover": {
								cursor: "pointer", // make row's look clickable
								backgroundColor: "rgba(74, 74, 74, 0.08)", // force bg color
							},
						},
					},
				},
				MuiTableCell: {
					root: {
						padding: "15px 20px 15px 30px",
					},
				},
				MuiIconButton: {
					root: {
						color: "#116466 !important", // primary color
					},
				},
				MUIDataTableHeadCell: {
					// force column width
					root: {
						"&:nth-child(1)": {
							width: 100,
						},
						"&:nth-child(2)": {
							width: 100,
						},
						"&:nth-child(3)": {
							width: 100,
						},
						"&:nth-child(4)": {
							width: 100,
						},
					},
				},
			},
		});
	}

	// state
	const [usersTable, setUsersTable] = useState([]); // data for mui table

	const columns = [
		{
			label: "Email",
			name: "Email",
			options: {
				filter: false,
			},
		},
		{
			label: "Phone",
			name: "Phone",
			options: {
				filter: false,
			},
		},
		{
			label: "Role",
			name: "Role",
		},
		{
			label: "Created Date",
			name: "Created Date",
		},
	];

	const options = {
		filter: true,
		responsive: "vertical",
		rowsPerPage: 10,
		rowsPerPageOptions: [10, 25, 50],
		filterType: "checkbox",
		selectableRows: "none",
		onRowClick: (_, index) => {
			// get ticket_id from data (based on index)
			const user_id = Object.values(users)[index.dataIndex].user_id;
			props.history.push("/user?user_id=" + user_id);
		},
	};

	// init
	useEffect(() => {
		if (!users) return; 

			// build array for mui table
			const usersTable = Object.values(users).map((user) => {
				return [
					user.email,
          user.phone? user.phone : "",
					user.role,
          convertDateTime(user.created_at)
				];
			});
		setUsersTable(usersTable);
	}, [users]);

	const buttons = [
    {
      text: "Create User",
      icon: <AddIcon className={classes.icon} />,
      func: () => props.history.push('/create_user')
    },
	];

	return (
		<React.Fragment>
			<PageTitle title="Accounts" buttons={buttons.length ? buttons : null} />
			<Grid container spacing={4}>
				<Grid item xs={12}>
					{!usersTable.length ? <Loader isSmall={props.isSmall} /> : null}
					<MuiThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							title="Accounts"
							data={usersTable}
							columns={columns}
							options={options}
						/>
					</MuiThemeProvider>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
