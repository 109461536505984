import React, { useState, useEffect, useContext } from "react";

import { userContext } from "../../context/User";
import { customersContext } from "../../context/Customers";

// components
import Loader from "../../components/Loader/Loader";

// mui
import {
  Grid, Paper, Typography, Checkbox, FormControlLabel, TextField, Divider
} from "@material-ui/core";

// styles
import useStyles from "./styles";

import CustomerInfoForm from "../../components/Customer/CustomerInfoForm";
import CustomerSelect from "../../components/Customer/CustomerSelect";
import { UpdateCustomerButton, CancelButton } from "../../components/Customer/Buttons";
import { UpdateUserButton } from "../../components/User/Buttons";

export default function Profile(props) {

  const user = useContext(userContext);
  const customers = useContext(customersContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const [customer, setCustomer] = useState({
    customer_id: "",
    rates: { "standard": 0.00 }
  });

  const [updatedUser, setUpdatedUser] = useState({
    name: null,
    phone: null,
    notifications: null,
  });

  useEffect(() => {
    if (!user || !customers) return;
    setUpdatedUser(user);
    setIsLoading(false);
  }, [user, customers])

  return isLoading ? <Loader /> : user && (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>

        <Typography variant="h3" gutterBottom>
          Profile Settings
        </Typography>

        <Grid container spacing={3}>
          {customer.customer_id === "" && (
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  User Settings
                </Typography>
              </Grid>

              <Grid item xs={6} >
                <TextField
                  fullWidth
                  type="tel"
                  value={updatedUser.name}
                  label="Name"
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      name: e.target.value
                    })
                  }}
                />
              </Grid>

              <Grid item xs={6} >
                <TextField
                  fullWidth
                  type="tel"
                  value={updatedUser.phone}
                  label="Phone Number"
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      phone: e.target.value,
                    })
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={updatedUser.notifications}
                      onChange={(e) => {
                        setUpdatedUser({
                          ...updatedUser,
                          notifications : e.target.checked
                        })
                      }}
                    />
                  }
                  label="Receive invoices via email"
                />
              </Grid>

              {!(
                user.notifications === updatedUser.notifications &&
                user.phone === updatedUser.phone &&
                user.name === updatedUser.name) &&
                <Grid item xs={12} md={6}>
                  <UpdateUserButton
                    callback={() => {
                      user.phone = updatedUser.phone;
                      user.notifications = updatedUser.notifications
                      user.name = updatedUser.name
                      props.history.push("/profile");
                    }}
                    user={{
                      user_id: user.user_id,
                      ...updatedUser
                    }}
                  />
                </Grid>
              }

              <Grid item xs={12}>
                <Divider variant="middle" style={{ marginBottom: "10px" }} />
              </Grid>
            </Grid>
          )}

          <Typography variant="h5" gutterBottom>
            Account Settings
          </Typography>

          <CustomerSelect customers={customers} customer={customer} setCustomer={setCustomer} />
          {customer.customer_id !== "" && (
            <CustomerInfoForm
              admin={user.role === "admin"}
              customer={customer}
              setCustomer={setCustomer}
            />)}

          {customer.customer_id !== "" && (
            <Grid item container xs={12} justifyContent="space-between" style={{ paddingTop: "25px" }}>
              <CancelButton callback={() => setCustomer({ customer_id: "", rates: { "standard": 0 } })} />
              <UpdateCustomerButton
                customer={customer}
                callback={() => {
                  customers[customer.customer_id] = customer;
                  setCustomer({ customer_id: "", rates: { "standard": 0 } })
                }}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid >
  )
}
