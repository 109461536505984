import React from "react";
import { Grid, Typography } from "@material-ui/core"

export default function TextRow({ value, label }) {
  return (
    <Grid container item xs={12} direction="row" alignItems="center">
      <Grid item xs={12} sm={4}>
        <span style={{ width: "100%", fontWeight: 600, }}>{label}:</span>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
}
