
import React from "react";
import currency from "currency.js";
import { Grid, FormControl, FormControlLabel, Typography, Checkbox } from "@material-ui/core"
import TextRow from "../Generic/TextRow";

function RatesView({ customer }) {
  return (
    <Grid container item xs={12}>
      {
        Object.entries(customer.rates).map(([key, value]) => {
          return (
            <Grid container>
              <Grid item xs={7}>
                <span>{key}</span>
              </Grid>

              <Grid item xs={5}>
                <Typography>{currency(value).format()}</Typography>
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
}

function NotesView({ customer }) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "600" }}>Notes</Typography>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            fontSize: "1.15rem",
            float: "left",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {customer.notes}
        </div>
      </Grid>
    </Grid>
  );
}

function EmailsView({ customer }) {
  return (
    customer.emails.map((email, index) => {
      return (
        <Grid container >
          <Grid item xs={10}>
            <Typography>{email}</Typography>
          </Grid>
        </Grid>
      );
    })
  );
}

function GridCheckBox({ value, label }) {
  return (
    <Grid item>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Checkbox checked={value} />}
          label={label}
        />
      </FormControl>
    </Grid>
  );
}

export function CustomerInfo({ customer, short, admin }) {
  return (
    <Grid item container xs={12} spacing={4}>

      <Grid item container>
        <TextRow label="Name" value={customer.name} />
        <TextRow label="City" value={customer.city} />
        <TextRow label="Address" value={customer.address} />
      </Grid>

      {!short && customer.contacts && customer.contacts.length > 0 && (
        <Grid container item xs={12}>
          <span style={{ width: "100%", fontWeight: 600 }}>Email Contacts:</span>
          <EmailsView customer={customer} />
        </Grid>
      )}

      {!short && (
        <Grid container item xs={12} direction="row" alignItems="center" justifyContent="space-around">
          <GridCheckBox label="After Hours" value={customer.after_hours} />
          <GridCheckBox label="Saturday Pickup" value={customer.saturday_pickup} />
        </Grid>
      )}

      {!short && customer.hasOwnProperty("tax_rate") && (
        <Grid item xs={12} >
          <TextRow label="Tax Rate" value={customer.tax_rate} />
        </Grid>
      )}


      {!short && (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <span style={{ width: "100%", fontWeight: 600 }}>Rates:</span>
          </Grid>
          <RatesView customer={customer} />
        </Grid>
      )}

      {customer.created_at && (
        <TextRow
          label="Creation date"
          value={
            Intl.DateTimeFormat(
              'en-US',
              {
                year: "numeric",
                month: "long",
                day: "numeric"
              }).format(new Date(customer.created_at))
          } />)}
      {(!short && admin && customer.notes !== "") && <NotesView customer={customer} />}
    </Grid>
  );
}
