import React from "react";
import { createTheme } from "@material-ui/core/styles";
import moment from 'moment';

export const columns = [
  {
    label: "Date",
    name: "Date",
    options: {
      filter: false,
      customBodyRender: date => (
        <React.Fragment>
          {moment(date).format('MM-DD-YYYY')}
        </React.Fragment>
      )
    },
  },
  {
    label: "Invoice #",
    name: "Invoice #",
    options: {
      filter: false,
    }
  },
  {
    label: "Acct Name",
    name: "Acct name",
    options: {
      filter: false,
    }
  },
  {
    label: "Items",
    name: "Items",
    options: {
      filter: false,
      customBodyRender: items => {
        return (
          <ul style={{ padding: "0px", margin: "0px", listStyleType: "none" }}>
            {
              items.map(item => {
                return (
                  <li>{`${item.quantity}x ${item.rate} ${item.inside !== null ? (item.inside ? "(inside)" : "(outside)") : ""}`}</li>
                );
              }
              )}
          </ul>
        );
      }
    },
  },
  {
    label: "Paid",
    name: "Paid",
    options: {
      filter: true,
      customBodyRender: paid => {
        return paid ? '\u2705' : '\u274c'
      }
    }
  },
];

export const options = {
  filter: true,
  responsive: 'vertical',
  sortOrder: {
    name: 'Date',
    direction: 'desc'
  },
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50],
  filterType: "checkbox",
  selectableRows: "none",
};

export function getMuiTheme() {
  return createTheme({
    overrides: {
      MuiTableRow: {
        hover: {
          '&$root': {
            '&:hover': {
              cursor: 'pointer', // make row's look clickable
              backgroundColor: 'rgba(74, 74, 74, 0.08)' // force bg color
            },
          }
        },
      },
      MuiIconButton: {
        root: {
          color: '#116466 !important', // primary color
        }
      },
      MUIDataTableHeadCell: { // force column width
        root: {
          '&:nth-child(1)': {
            width: 150
          },
          '&:nth-child(2)': {
            width: 150
          }
        }
      }
    }
  });
}
